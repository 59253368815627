import React, { FC } from 'react';
import { Container } from './Container/Container';
import { ContentfulPageKontakt } from '../model/generated/graphql';
import { SectionHeader, SectionTitle } from '../pages';
import styled from 'styled-components';
import LocationIcon from '../../static/icons/place_black_24dp.svg';
import { pxToRem } from '../utils/pxToRem';
import PhoneIcon from '../../static/icons/call_black_24dp.svg';
import EmailIcon from '../../static/icons/email_black_24dp.svg';
import ScheduleIcon from '../../static/icons/schedule_black_24dp.svg';
import InstagramIcon from '../../static/icons/instagram.svg';
import FacebookIcon from '../../static/icons/facebook.svg';
import BankIcon from '../../static/icons/bank.svg';
import { rem } from 'polished';
import BooksyIcon from '../../static/icons/booksy.svg';

const LazyGoogleMap = React.lazy<FC>(() => import('../components/LazyGoogleMap').then((mod) => mod));

type Props = {
    data: ContentfulPageKontakt;
    hideTitle?: boolean;
    shouldRenderMap?: boolean;
};
export const ContactSection: FC<Props> = ({ data, hideTitle, shouldRenderMap }) => {
    const isSSR = typeof window === 'undefined';

    return (
        <Container>
            {/*<SectionHeader>*/}
            {/*    {!hideTitle && <SectionTitle>{data.title}</SectionTitle>}{' '}*/}

            {/*</SectionHeader>*/}

            <Wrapper>
                <Address>
                    <p style={{ fontWeight: 500 }}>{data.companyName}</p>
                    <ContactItem>
                        <LocationIcon width={24} fill="#36355D" aria-hidden />
                        <div>
                            <ContactText>
                                {data.street}
                                <br />
                                {data.zipCode} {data.city}
                            </ContactText>
                        </div>
                    </ContactItem>

                    <ContactItem>
                        <EmailIcon width={20} fill="#36355D" aria-hidden />

                        <div>
                            <ContactText>
                                <ContactLink href={`mailto:${data.email}`}>{data.email}</ContactLink>
                            </ContactText>
                        </div>
                    </ContactItem>

                    <ContactItem>
                        <PhoneIcon width={20} fill="#36355D" aria-hidden />
                        <div>
                            <ContactText>
                                <ContactLabel>{data.phone1Label}:</ContactLabel>
                                <ContactLink href={`tel:+48${data.phone1Number}`}>{data.phone1Number}</ContactLink>
                            </ContactText>
                            <ContactText>
                                <ContactLabel>{data.phone2Label}:</ContactLabel>
                                <ContactLink href={`tel:+48${data.phone2Number}`}>{data.phone2Number}</ContactLink>
                            </ContactText>
                            <ContactText>
                                <ContactLabel> {data.phone3Label}:</ContactLabel>
                                <ContactLink href={`tel:+48${data.phone3Number}`}>{data.phone3Number}</ContactLink>
                            </ContactText>
                        </div>
                    </ContactItem>

                    <ContactItem>
                        <ScheduleIcon width={20} fill="#36355D" aria-hidden />
                        <div>
                            <ContactText>
                                <ContactLabel> {data.openingHoursLabel}:</ContactLabel>
                                <ContactLabel>{data.openingHoursValue}</ContactLabel>
                            </ContactText>
                        </div>
                    </ContactItem>

                    <ContactItem>
                        <BankIcon width={20} fill="#36355D" aria-hidden />
                        <div>
                            <ContactText>
                                <ContactLabel>
                                    {data.bankAccountLabel} {data.bankAccount}
                                </ContactLabel>
                            </ContactText>
                        </div>
                    </ContactItem>

                    {data.facebookLink && data.facebookLink !== ' ' && (
                        <ContactItem>
                            <FacebookIcon width={20} fill="#36355D" aria-hidden />
                            <div>
                                <ContactText>
                                    <ContactLink href={data.facebookLink} target={'_blank'}>
                                        Facebook
                                    </ContactLink>
                                </ContactText>
                            </div>
                        </ContactItem>
                    )}

                    {data.instagramLink && data.instagramLink !== ' ' && (
                        <ContactItem>
                            <InstagramIcon width={20} fill="#36355D" aria-hidden />
                            <div>
                                <ContactText>
                                    <ContactLink href={data.instagramLink} target={'_blank'}>
                                        Instagram
                                    </ContactLink>
                                </ContactText>
                            </div>
                        </ContactItem>
                    )}
                </Address>
                <MapWrapper>
                    {!isSSR && shouldRenderMap && (
                        <React.Suspense fallback={<div />}>
                            <LazyGoogleMap />
                        </React.Suspense>
                    )}
                </MapWrapper>
            </Wrapper>
        </Container>
    );
};
export const AdBanner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${rem(100)};
    width: 80%;
    border-radius: 55px;
    margin: ${pxToRem(50)} auto ${pxToRem(100)};
    background: rgb(0, 166, 156);
    background: linear-gradient(90deg, rgba(0, 166, 156, 1) 0%, rgba(59, 59, 180, 1) 74%, rgba(54, 53, 93, 1) 100%);
    text-align: center;
    @media (max-width: ${pxToRem(600)}) {
        width: 95%;
        height: 80px;
    }
`;

export const Booksy = styled(BooksyIcon)`
    width: 160px;
    fill: white;
    margin-left: 16px;
    @media (max-width: ${pxToRem(600)}) {
        width: 110px;
    }
    @media (max-width: ${pxToRem(480)}) {
        width: 90px;
    }
`;

export const AdBannerTitle = styled.a`
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    @media (max-width: ${pxToRem(600)}) {
        font-size: 1.5rem;
    }
    @media (max-width: ${pxToRem(480)}) {
        font-size: 1.2rem;
    }
    &:hover {
        text-decoration: underline;
    }
`;
const Address = styled.address`
    font-style: normal;
    flex-grow: 1;
`;

const Wrapper = styled.div`
    position: relative;
    padding: 1px;
    display: flex;
    box-sizing: border-box;
    @media (max-width: ${pxToRem(1200)}) {
        flex-direction: column;
    }
`;

const MapWrapper = styled.div`
    flex-grow: 4;
`;

export const ContactItem = styled.div`
    display: flex;
    margin-bottom: ${pxToRem(20)};
`;

export const ContactText = styled.p`
    margin: 0 0 ${pxToRem(12)} ${pxToRem(12)};
`;

export const ContactLabel = styled.span`
    padding-right: ${pxToRem(12)};
`;

export const ContactLink = styled.a`
    color: var(--color-primary);
    font-weight: 500;
    position: relative;
    z-index: 1;
    &:before {
        position: absolute;
        bottom: 4px;
        content: '';
        height: 0.2em;
        left: -3px;
        right: -3px;
        background-color: var(--color-secondary);
        transform: scaleX(0);
        transition: transform 200ms ease-in-out;
        z-index: -1;
        transform-origin: left;
    }
    &:hover:before {
        transform: scaleX(1);
    }
`;
