import React, { FC, useContext } from 'react';
import { graphql } from 'gatsby';
import {
    ContentfulAktualnosciWpis,
    ContentfulAktualnosciWpisConnection,
    ContentfulPageAktualnosci,
    ContentfulPageKontakt,
} from '../model/generated/graphql';
import { PageContentWrapper } from '../components/PageContentWrapper/PageContentWrapper';
import { Container } from '../components/Container/Container';
import { PageRow } from '../components/PageRow/PageRow';
import {
    ContentfulRichTextGatsbyReference,
    renderRichText,
    RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

import { PageTransition } from '../components/PageTransition/PageTransition';
import Seo from '../components/Seo/Seo';
import LocaleContext from '../context/Locale';
import FileIcon from '../../static/icons/file.svg';
import { ContactItem, ContactLink, ContactText } from '../components/ContactSection';
import {
    ArticleImg,
    ArticleLayout,
    Aside,
    GalleryImg,
    GalleryWrapper,
    RecentPost,
    RecentPostsHeader,
    SectionTitle,
} from '../components/News/NewsTemplateComponents';

type Props = {
    data: {
        contentfulPageAktualnosci: ContentfulPageAktualnosci;
        contentfulAktualnosciWpis: ContentfulAktualnosciWpis;
        allContentfulAktualnosciWpis: ContentfulAktualnosciWpisConnection;
        contentfulPageKontakt: ContentfulPageKontakt;
    };
};

export const NewsTemplate: FC<Props> = ({ data }) => {
    const { contentfulAktualnosciWpis } = data;
    const { edges } = data.allContentfulAktualnosciWpis;
    const { activeLocale } = useContext(LocaleContext);

    return (
        <>
            <Seo locale={activeLocale} seo={{ title: contentfulAktualnosciWpis.title }} />
            <PageContentWrapper title={data.contentfulPageAktualnosci.title}>
                <Container>
                    <PageTransition>
                        <PageRow>
                            <ArticleLayout>
                                <article>
                                    {contentfulAktualnosciWpis.picture && (
                                        <ArticleImg image={contentfulAktualnosciWpis.picture.gatsbyImageData} alt="" />
                                    )}
                                    <p>{contentfulAktualnosciWpis.dataPublikacji}</p>
                                    <h2>{contentfulAktualnosciWpis.title}</h2>
                                    {contentfulAktualnosciWpis.opis && (
                                        <div>
                                            {renderRichText(
                                                contentfulAktualnosciWpis.opis as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                                            )}
                                        </div>
                                    )}

                                    {contentfulAktualnosciWpis.sekcje &&
                                        contentfulAktualnosciWpis.sekcje.map((el) => (
                                            <div>
                                                {el.tytu && <SectionTitle>{el.tytu}</SectionTitle>}
                                                {el.content && (
                                                    <div>
                                                        {renderRichText(
                                                            el.content as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                                                        )}
                                                    </div>
                                                )}
                                                {el.gallery && (
                                                    <GalleryWrapper>
                                                        {el.gallery.map((el) => (
                                                            <GalleryImg image={el.gatsbyImageData} alt="" />
                                                        ))}
                                                    </GalleryWrapper>
                                                )}
                                            </div>
                                        ))}

                                    {contentfulAktualnosciWpis.doPobraniaPliki && (
                                        <div style={{ listStyle: 'none' }}>
                                            <SectionTitle>Do pobrania</SectionTitle>
                                            {contentfulAktualnosciWpis.doPobraniaOpis && (
                                                <div>
                                                    {renderRichText(
                                                        contentfulAktualnosciWpis.doPobraniaOpis as RenderRichTextData<ContentfulRichTextGatsbyReference>,
                                                    )}
                                                </div>
                                            )}
                                            {contentfulAktualnosciWpis.doPobraniaPliki.map((el) => (
                                                <ContactItem>
                                                    <FileIcon width={20} fill="#36355D" aria-hidden />
                                                    <div>
                                                        <ContactText style={{ margin: 0 }}>
                                                            <ContactLink href={el.file.url} target={'_blank'}>
                                                                {el.title}
                                                            </ContactLink>
                                                        </ContactText>
                                                    </div>
                                                </ContactItem>
                                            ))}

                                            {data.contentfulAktualnosciWpis?.galeria && (
                                                <GalleryWrapper>
                                                    {data.contentfulAktualnosciWpis?.galeria.map((el) => (
                                                        <GalleryImg image={el.gatsbyImageData} alt="" />
                                                    ))}
                                                </GalleryWrapper>
                                            )}
                                        </div>
                                    )}
                                </article>
                                <Aside>
                                    <RecentPostsHeader>Aktualności</RecentPostsHeader>
                                    <ul>
                                        {edges.map(({ node }) => {
                                            if (node.contentful_id === contentfulAktualnosciWpis.contentful_id)
                                                return null;
                                            return <RecentPost node={node} key={node.contentful_id} />;
                                        })}
                                    </ul>
                                </Aside>
                            </ArticleLayout>
                        </PageRow>
                    </PageTransition>
                </Container>
            </PageContentWrapper>
        </>
    );
};

export default NewsTemplate;

export const pageQuery = graphql`
    query News($node_locale: String, $id: String) {
        contentfulPageAktualnosci(contentful_id: { eq: "rsvcHduE7E9XIPus3LRd4" }, node_locale: { eq: $node_locale }) {
            id
            title
        }
        contentfulAktualnosciWpis(node_locale: { eq: $node_locale }, contentful_id: { eq: $id }) {
            id
            title
            slug
            contentful_id
            picture {
                gatsbyImageData(
                    layout: CONSTRAINED
                    cropFocus: CENTER
                    placeholder: BLURRED
                    quality: 90
                    width: 800
                    height: 500
                )
            }
            galeria {
                gatsbyImageData(layout: FULL_WIDTH, cropFocus: CENTER, placeholder: BLURRED, quality: 90)
            }
            sekcje {
                tytu
                content {
                    raw
                }
                gallery {
                    gatsbyImageData(layout: FULL_WIDTH, cropFocus: CENTER, placeholder: BLURRED, quality: 90)
                }
            }
            doPobraniaPliki {
                title
                file {
                    url
                    fileName
                }
            }
            doPobraniaOpis {
                raw
            }
            opis {
                raw
            }
            dataPublikacji(formatString: "DD.MM.YYYY")
        }
        allContentfulAktualnosciWpis(
            filter: { node_locale: { eq: $node_locale } }
            sort: { fields: dataPublikacji, order: DESC }
        ) {
            edges {
                node {
                    title
                    dataPublikacji(formatString: "DD.MM.YYYY")
                    slug
                    contentful_id
                }
            }
        }
        contentfulPageKontakt(contentful_id: { eq: "4CI60KPsDdc3LGC7S3xH9k" }, node_locale: { eq: $node_locale }) {
            ...contact
        }
    }
`;
